const getFormValues = form => {
  const values = {};

  for (const el of form.elements) {
    const name = el.name || el.id;
    let value = el.value;
    if (el.type === "checkbox") {
      values[name] = el.checked;
    } else if (value) {
      if (isNumber(el)) {
        value = Number(value);
      }

      values[name] = value;
    }
  }

  return values;
};

function isNumber(el) {
  if (["number", "range"].includes(el.type)) {
    return true;
  }

  const dataType = el.getAttribute("data-type");
  return dataType === "number";
}

export default getFormValues;
