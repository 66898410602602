import React, { createContext, useState } from "react";

const MapContext = createContext();

const MapContextProvider = ({ children }) => {
  const [geoCoordinates, setGeoCoordinates] = useState({
    latitude: 0,
    longitude: 0
  });
  return (
    <MapContext.Provider value={{ setGeoCoordinates, geoCoordinates }}>
      {children}
    </MapContext.Provider>
  );
};

export { MapContext, MapContextProvider };
