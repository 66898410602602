import React, { useState } from "react";
import styled from "styled-components";

import Constants from "./Constants";
import getFormValues from "./getFormValues";
import Modal from "./Modal";
import EditProperties from "./EditProperties";

const ToolsBorder = styled.div`
  height: 100%;
  border-left: 1px solid black;
  overflow-y: auto;

  @media screen and (max-width: ${Constants.mediaMaxWidth}) {
    border-left: initial;
    border-top: 1px solid black;
    overflow-x: auto;
  }
`;

const GroupHeader = styled.h3`
  margin-bottom: 4px;
`;

const ToolSelect = styled.form`
  > * + * {
    margin-top: 4px;
  }
`;

const ToolsContainer = styled.div`
  padding: 2px 8px 8px 8px;

  > * + * {
    margin-top: 8px;
  }
`;

const ToolRow = styled.div`
  display: flex;
  > * + * {
    margin-left: 8px;
  }
`;

const ToolGroup = styled.div``;

const MapViewContainer = styled.div`
  display: flex;
  align-items: baseline;

  > * + * {
    margin-left: 8px;
  }
`;

let propertyState = {};
const clearPropertyState = () => {
  propertyState = {};
};

const onSubmit = (e, tool, onApply, onError) => {
  e.preventDefault();
  const values =
    tool === "editProperties"
      ? propertyState
      : getFormValues(e.nativeEvent.target);
  try {
    onApply(values);
  } catch (e) {
    alert(
      "Unfortunately an error occured while trying to perform the operation:\n" +
        e.message
    );
    onError();
  }
  clearPropertyState();
};

const updatePropertyState = (currentId, currentMapObjectKey, values) => {
  if (!(currentMapObjectKey in propertyState)) {
    propertyState[currentMapObjectKey] = {};
  }
  propertyState[currentMapObjectKey][currentId] = values;
};

const Tools = ({
  onToolChanged,
  tools,
  currentTool,
  currentGeography,
  onApply,
  onUndo,
  onRedo,
  onOpen,
  onStore,
  onMapObjectKeyChanged,
  currentMapObjectKey,
  mapObjectKeys,
  onMapObjectDelete
}) => {
  const [showProperties, setShowProperties] = useState(false);
  const callToolChanges = x => {
    clearPropertyState();
    onToolChanged(x);
  };

  const getCurrentGeography = () => {
    if (currentMapObjectKey in propertyState) {
      return (
        propertyState[currentMapObjectKey][currentGeography.id] ||
        currentGeography
      );
    }
    return currentGeography;
  };

  return (
    <ToolsBorder>
      <ToolsContainer>
        <ToolGroup>
          <GroupHeader>Tool</GroupHeader>
          <ToolSelect
            onSubmit={e =>
              onSubmit(e, currentTool, onApply, () =>
                callToolChanges(currentTool)
              )
            }
          >
            {tools.map(({ id, name }) => (
              <ToolRow key={id}>
                <input
                  type="radio"
                  id={`tool_${id}`}
                  value={id}
                  checked={id === currentTool}
                  onChange={e => callToolChanges(id)}
                />
                <label htmlFor={`tool_${id}`}>{name}</label>
                {id === "quantize" && (
                  <div>
                    <label htmlFor="quantization">value</label>
                    <input
                      style={{ marginLeft: 8 }}
                      id="quantization"
                      type="text"
                    />
                  </div>
                )}
                {id === "editProperties" && (
                  <button
                    type="button"
                    onClick={() => setShowProperties(true)}
                    disabled={
                      currentTool !== "editProperties" || !currentGeography
                    }
                  >
                    edit
                  </button>
                )}
              </ToolRow>
            ))}
            <button type="submit">Apply</button>
          </ToolSelect>
        </ToolGroup>
        <ToolGroup>
          <button onClick={onUndo}>Undo</button>
          <button onClick={onRedo}>Redo</button>
        </ToolGroup>
        <ToolGroup>
          <GroupHeader>File</GroupHeader>
          <button onClick={onOpen}>Open</button>
          <button onClick={onStore}>Save</button>
        </ToolGroup>
        <ToolGroup>
          <GroupHeader>Select view</GroupHeader>
          <MapViewContainer>
            <select onChange={e => onMapObjectKeyChanged(e.target.value)}>
              {mapObjectKeys.map(key => (
                <option
                  key={key}
                  value={key}
                  defaultValue={currentMapObjectKey}
                >
                  {key}
                </option>
              ))}
            </select>
            <button
              disabled={mapObjectKeys.length <= 1}
              onClick={() => onMapObjectDelete(currentMapObjectKey)}
            >
              Delete
            </button>
          </MapViewContainer>
        </ToolGroup>
      </ToolsContainer>
      {showProperties && (
        <Modal>
          <EditProperties
            geography={getCurrentGeography()}
            onApply={values => {
              setShowProperties(false);
              updatePropertyState(
                currentGeography.id,
                currentMapObjectKey,
                values
              );
            }}
            onCancel={() => setShowProperties(false)}
          />
        </Modal>
      )}
    </ToolsBorder>
  );
};

export default Tools;
