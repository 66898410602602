import React from "react";
import styled from "styled-components";

import getFormValues from "./getFormValues";

const Inputs = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 10px;
  }
`;

const LabelAndInput = styled.div`
  display: flex;
  flex-direction: column;

  flex: ${p => p.flex || 0};

  @media screen and (max-height: 600px) {
    flex: 0;
  }
`;

const JsonInput = styled.textarea`
  flex: 1;
`;

const CancelCloseContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  > * + * {
    margin-left: 8px;
  }
`;

const onSubmit = (e, onApply, onCancel) => {
  e.preventDefault();
  try {
    const values = getFormValues(e.nativeEvent.target);
    const { idInput, typeInput, propertiesInput, geometryInput } = values;

    onApply({
      id: idInput,
      type: typeInput,
      properties: JSON.parse(propertiesInput),
      geometry: JSON.parse(geometryInput)
    });
  } catch (e) {
    alert(
      "Unfortunately an error occured while trying to perform the operation."
    );
  }
};

const EditProperties = ({ geography, onApply, onCancel }) => {
  return (
    <form
      style={{ height: "100%" }}
      onSubmit={e => onSubmit(e, onApply, onCancel)}
    >
      <Inputs style={{ height: "100%" }}>
        <LabelAndInput>
          <label htmlFor="idInput">Id</label>
          <input id="idInput" type="text" defaultValue={geography.id} />
        </LabelAndInput>
        <LabelAndInput>
          <label htmlFor="typeInput">Type</label>
          <input id="typeInput" type="text" defaultValue={geography.type} />
        </LabelAndInput>
        <LabelAndInput flex={1}>
          <label htmlFor="propertiesInput">Properties</label>
          <JsonInput
            id="propertiesInput"
            defaultValue={JSON.stringify(geography.properties, null, 2)}
          />
        </LabelAndInput>
        <LabelAndInput flex={2}>
          <label htmlFor="geometryInput">Geometry</label>
          <JsonInput
            id="geometryInput"
            defaultValue={JSON.stringify(geography.geometry, null, 2)}
          />
        </LabelAndInput>
        <CancelCloseContainer>
          <button>Ok</button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </CancelCloseContainer>
      </Inputs>
    </form>
  );
};

export default EditProperties;
