const earthRadius = 6371e3; // metres

const degreeToRadians = deg => (Math.PI * deg) / 180;

/**
 * Calculates the distance in meters between two geo coordinates in dms format.
 *
 * @param {number[2]} The longitude and latitude for the first coordinate.
 * @param {number[2]} The longitude and latitude for the second coordinate.
 * @return {number} The distance in meters.
 */
const distance = ([long1, lat1], [long2, lat2]) => {
  var φ1 = degreeToRadians(lat1);
  var φ2 = degreeToRadians(lat2);
  var Δφ = degreeToRadians(lat2 - lat1);
  var Δλ = degreeToRadians(long2 - long1);

  var a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return earthRadius * c;
};

const nearestNeighbor = (coordinatesSet, point) => {
  let nearest;
  for (let i = 0; i < coordinatesSet.length; i++) {
    const coordinates = coordinatesSet[i][0];
    for (let coordinateIndex = 0; coordinateIndex < coordinates.length; coordinateIndex++) {
      const coordinate = coordinates[coordinateIndex];
      const dist = distance(coordinate, point);
      if (!nearest || dist < nearest.distance) {
        nearest = {
          distance: dist,
          setIndex: i,
          coordinateIndex: coordinateIndex,
          coordinate
        };
      }
    }
  }
  return nearest;
};

export { distance, nearestNeighbor };
