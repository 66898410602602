import { findDOMNode } from "react-dom";
import React, { createRef } from "react";
import styled from "styled-components";

import * as topojson from "topojson-client";

import * as d3 from "d3";
import * as d3geo from "d3-geo";
import { geoMiller } from "d3-geo-projection";

import Colors from "./Colors";
import Constants from './Constants';

const MainContainer = styled.div`
  position: relative;
`;

const ZoomButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: 16px;
  margin-bottom: 24px;

  > * + * {
    margin-top: 4px;
  }


  @media screen and (max-width: ${Constants.mediaMaxWidth}) {
    bottom: 25%;
  }
`;

const ZoomButton = styled.button`
  background-color: white;
  font-weight: bold;
  border: 2px solid darkgray;
  border-radius: 8px;
  padding: 8px;
  height: 36px;
  width: 36px;
  font-size: 1em;
`;

const SvgContainer = styled.div``;

class Map extends React.Component {
  constructor() {
    super();

    this.onResize = this.onResize.bind(this);

    this.svgRef = createRef();
    this.selectedGeographies = {};
  }

  renderMap() {
    this.innerMap && this.innerMap.remove();

    const {
      map,
      mapObjectKey,
      onGeographyClicked,
      onGeographyDoubleClicked
    } = this.props;

    const {
      mapRenderGroup,
      projection,
      gatherClickInformation
    } = this.d3Artifacts;

    const innerMap = mapRenderGroup.append("g");
    this.innerMap = innerMap;

    innerMap
      .selectAll("path")
      .data(topojson.feature(map, map.objects[mapObjectKey]).features)
      .enter()
      .append("path")
      .attr("d", d3geo.geoPath().projection(projection))
      .attr("fill", "black")
      .attr("stroke", Colors.lightgrey)
      .attr("stroke-width", "0.5")
      .attr("vector-effect", "non-scaling-stroke")
      .on(
        "dblclick",
        onGeographyDoubleClicked &&
          function(geography) {
            const clickInfo = gatherClickInformation(
              geography,
              d3.select(this)
            );
            onGeographyDoubleClicked(clickInfo);
          }
      )
      .on(
        "click",
        onGeographyClicked &&
          function(geography) {
            const clickInfo = gatherClickInformation(
              geography,
              d3.select(this)
            );
            onGeographyClicked(clickInfo);
          }
      );
  }

  componentDidMount() {
    const centerLongitude = 0;

    const projection = geoMiller().rotate([-centerLongitude, 0, 0]);

    const svg = d3.select(this.svgRef.current).append("svg");

    window.addEventListener("resize", this.onResize);

    const mapGroup = svg.append("g");
    const mapRenderGroup = mapGroup.append("g");

    const mousePointer = mapGroup
      .append("line")
      .attr("display", "none")
      .attr("pointer-events", "none")
      .attr("stroke", "hotpink")
      .attr("stroke-width", 2)
      .attr("vector-effect", "non-scaling-stroke");

    const component = this;

    const getCoordinates = () => {
      const mouseCoordinates = d3.mouse(svg.node());
      const transform = d3.zoomTransform(svg.node());
      const xyCoordinates = transform.invert(mouseCoordinates);
      const geoCoordinates = projection.invert(xyCoordinates);
      return { xyCoordinates, geoCoordinates };
    };

    svg.on("mousemove", function() {
      const { geoCoordinates, xyCoordinates } = getCoordinates();
      component.props.onMouseMove &&
        component.props.onMouseMove({
          coordinates: { xy: xyCoordinates, geo: geoCoordinates },
          svg: { mousePointer }
        });
    });

    const gatherClickInformation = (geography, clickedItem) => {
      const { geoCoordinates, xyCoordinates } = getCoordinates();
      return {
        geography,
        coordinates: {
          xy: xyCoordinates,
          geo: geoCoordinates
        },
        svg: {
          mousePointer,
          clickedItem,
          layer: mapGroup
        }
      };
    };

    const zoom = d3
      .zoom()
      .extent([
        [0, 0],
        [900, 550]
      ])
      .scaleExtent([0.1, 10])
      .on("zoom", function() {
        mapGroup.attr("transform", d3.event.transform);
      });

    svg.call(zoom).on("dblclick.zoom", null);

    const setSvgSize = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      svg.attr("viewBox", `0 0 ${width} ${height}`);
      svg.call(
        zoom.transform,
        d3.zoomIdentity.scale(height / 450).translate(-100, -20)
      );
    };

    setSvgSize();

    this.zoomIn = () => zoom.scaleBy(svg.transition().duration(250), 1.2);
    this.zoomOut = () => zoom.scaleBy(svg.transition().duration(250), 0.8);

    this.d3Artifacts = {
      onResize: setSvgSize,
      mapRenderGroup,
      projection,
      gatherClickInformation
    };

    this.renderMap();
  }

  componentWillUnmount() {
    d3.select(findDOMNode(this.svgRef.current)).on("mousemove", null);
    window.removeEventListener("resize", this.onResize);
  }

  onResize(e) {
    this.d3Artifacts.onResize();
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.map !== this.props.map ||
      oldProps.mapObjectKey !== this.props.mapObjectKey
    ) {
      this.renderMap();
    }
  }

  render() {
    return (
      <MainContainer>
        <ZoomButtonContainer>
          <ZoomButton onClick={() => this.zoomIn && this.zoomIn()}>
            +
          </ZoomButton>
          <ZoomButton onClick={() => this.zoomOut && this.zoomOut()}>
            -
          </ZoomButton>
        </ZoomButtonContainer>
        <SvgContainer ref={this.svgRef} />
      </MainContainer>
    );
  }
}

const MapSelector = ({
  map,
  mapObjectKey,
  cursor,
  onGeographyClicked,
  onGeographyDoubleClicked,
  onMouseMove
}) => {
  return (
    <div>
      <div style={{ cursor }}>
        <Map
          onGeographyClicked={onGeographyClicked}
          onGeographyDoubleClicked={onGeographyDoubleClicked}
          map={map}
          mapObjectKey={mapObjectKey}
          onMouseMove={onMouseMove}
        />
      </div>
    </div>
  );
};

export default MapSelector;
