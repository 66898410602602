import React from "react";
import styled from "styled-components";

const Content = styled.div`
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: 80%;

  overflow-y: 'auto';

  @media screen and (max-height: 600px) {
    height: 100%;
    margin: 0 auto;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Modal = ({ children }) => {
  return (
    <ModalBackground>
      <Content>{children}</Content>
    </ModalBackground>
  );
};

export default Modal;
