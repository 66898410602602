import * as topojson from "topojson-client";
import * as toposerver from "topojson-server";

import { nearestNeighbor } from "./distance";

export default class Topostore {
  constructor(onMapChanged) {
    this.onMapChanged = onMapChanged || (_ => {});
    this.openMap = this.openMap.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
    this.updateProperties = this.updateProperties.bind(this);
    this.merge = this.merge.bind(this);
    this.quantize = this.quantize.bind(this);
    this.split = this.split.bind(this);
    this.atomize = this.atomize.bind(this);
    this.remove = this.remove.bind(this);
    this.commitModified = this.commitModified.bind(this);
    this.getCurrentMap = this.getCurrentMap.bind(this);
    this.selectMapObjectKey = this.selectMapObjectKey.bind(this);
    this.deleteMapObject = this.deleteMapObject.bind(this);
  }

  addIds(map) {
    for (const o of Object.values(map.objects)) {
      const ids = o.geometries.filter(m => m.id).map(m => m.id);
      let nextId = 0;
      for (const geometry of o.geometries) {
        if (!geometry.id) {
          do {
            nextId++;
          } while (ids.includes(nextId.toString()));
          geometry.id = nextId.toString();
        }
      }
    }
  }

  openMap(map, mapObjectKey) {
    this.addIds(map);
    this.revisions = [map];
    this.undoneRevisions = [];
    this.mapObjectKey = mapObjectKey;
  }

  selectMapObjectKey(mapObjectKey) {
    this.mapObjectKey = mapObjectKey;
  }

  undo() {
    if (this.revisions.length <= 1) return;
    const undone = this.revisions.pop();
    if (undone) {
      this.undoneRevisions.push(undone);
      this.onMapChanged(this.revisions.slice(-1)[0]);
    }
  }

  redo() {
    const redone = this.undoneRevisions.pop();
    if (redone) {
      this.revisions.push(redone);
      this.onMapChanged(redone);
    }
  }

  merge(mergeInstruction) {
    this.undoneRevisions = [];
    const current = JSON.parse(
      JSON.stringify(this.revisions[this.revisions.length - 1])
    );
    const newId = mergeInstruction.ids.join("+");

    const resulting = [];
    const toMerge = [];

    for (const t of current.objects[this.mapObjectKey].geometries) {
      if (mergeInstruction.ids.includes(t.id)) toMerge.push(t);
      else resulting.push(t);
    }

    const mergedParts = topojson.mergeArcs(current, toMerge);

    mergedParts.id = newId;
    resulting.push(mergedParts);
    current.objects[this.mapObjectKey].geometries = resulting;

    // apply changes
    this.revisions.push(current);
    this.onMapChanged(current);
  }

  getCurrentMap = () => {
    this.undoneRevisions = [];
    const current = JSON.parse(
      JSON.stringify(this.revisions[this.revisions.length - 1])
    );

    const result = {};
    for (const key of Object.keys(current.objects)) {
      result[key] = topojson.feature(current, current.objects[key]);
    }
    return result;
  };

  commitModified = (modified, quantization) => {
    const changed = toposerver.topology(modified, quantization);
    this.revisions.push(changed);
    this.onMapChanged(changed);
  };

  deleteMapObject(mapObjectKey) {
    const current = this.getCurrentMap();
    delete current[mapObjectKey];
    this.commitModified(current);
  }

  updateProperties(values) {
    const current = this.getCurrentMap();
    for (const mapObjectKey of Object.keys(current)) {
      const newFeatures = values[mapObjectKey];
      if (!newFeatures) continue;

      const idsToRemove = Object.keys(values);
      const mergedFeatures = [
        ...current[mapObjectKey].features.filter(
          id => !idsToRemove.includes(id)
        ),
        ...Object.values(newFeatures)
      ];

      const allIds = mergedFeatures.map(f => f.id);
      const uniqueIds = [...new Set(allIds)];
      if (allIds.length !== uniqueIds.length) {
        throw new Error("Ids must be unique!");
      }

      current[mapObjectKey].features = mergedFeatures;
    }
    this.commitModified(current);
  }

  split(splitInstruction) {
    const current = this.getCurrentMap();
    const features = current[this.mapObjectKey];

    for (const inst of splitInstruction) {
      const toSplit = features.features.filter(f => f.id === inst.id)[0];
      const newGeometry = this.splitSingle(toSplit.geometry, inst.splitLine);
      toSplit.geometry = newGeometry;
    }

    this.commitModified(current);
  }

  quantize(quantizeInstruction) {
    const current = this.getCurrentMap();
    this.commitModified(current, quantizeInstruction.quantization);
  }

  atomize(atomizeInstruction) {
    const current = this.getCurrentMap();
    const features = current[this.mapObjectKey];

    const toAtomize = features.features.filter(g =>
      atomizeInstruction.ids.includes(g.id)
    );

    for (const a of toAtomize) {
      if (a.geometry.type.toLowerCase() !== "multipolygon") continue;

      const index = features.features.indexOf(a);
      const polygons = [];

      for (let i = 0; i < a.geometry.coordinates.length; i++) {
        polygons.push({
          type: "Feature",
          properties: a.properties,
          id: `${a.id}-atom-${i}`,
          geometry: {
            type: "Polygon",
            coordinates: a.geometry.coordinates[i]
          }
        });
      }

      features.features.splice(index, 1, ...polygons);
    }

    this.commitModified(current);
  }

  remove(removeInstruction) {
    const current = this.getCurrentMap();
    const features = current[this.mapObjectKey];

    const toRemove = features.features.filter(g =>
      removeInstruction.ids.includes(g.id)
    );

    for (const a of toRemove) {
      const index = features.features.indexOf(a);
      features.features.splice(index, 1);
    }

    this.commitModified(current);
  }

  splitSingle(geography, splitLine) {
    let { type, coordinates, ...rest } = geography;
    if (!["polygon", "multipolygon"].includes(type.toLowerCase()))
      throw new Error("type must be polygon or multipolygon, but was", type);

    if (type.toLowerCase() === "polygon") {
      const foo = coordinates.map(x => [x]);
      coordinates = foo;
    }

    const startPoint = splitLine[0];
    const endPoint = splitLine[splitLine.length - 1];
    let nearestStart = nearestNeighbor(coordinates, startPoint);
    let nearestEnd = nearestNeighbor(coordinates, endPoint);

    // Ensure start and end point are within the same polygon
    if (nearestEnd.setIndex !== nearestStart.setIndex) {
      if (nearestStart.distance < nearestEnd.distance) {
        nearestEnd = nearestNeighbor(
          [coordinates[nearestStart.setIndex]],
          endPoint
        );
      } else {
        nearestStart = nearestNeighbor(
          [coordinates[nearestEnd.setIndex]],
          startPoint
        );
      }
    }

    if (nearestStart.coordinateIndex > nearestEnd.coordinateIndex) {
      [nearestStart, nearestEnd] = [nearestEnd, nearestStart];
      splitLine = reverse(splitLine);
    }

    const toSplit = coordinates[nearestStart.setIndex][0];

    let taleArray = toSplit.splice(nearestEnd.coordinateIndex);
    let middleArray = toSplit.splice(nearestStart.coordinateIndex);
    let headArray = toSplit;

    const polygon1 = [
      ...middleArray,
      nearestEnd.coordinate,
      ...reverse(splitLine),
      nearestStart.coordinate,
      middleArray[0]
    ];

    const polygon2 = [
      ...headArray,
      nearestStart.coordinate,
      ...splitLine,
      nearestEnd.coordinate,
      ...taleArray
    ];

    let newCoordinates = coordinates;
    newCoordinates.splice(nearestEnd.setIndex, 1);
    newCoordinates.push([polygon1]);
    newCoordinates.push([polygon2]);

    return { ...rest, type: "MultiPolygon", coordinates: newCoordinates };
  }
}

const reverse = array => [...array].reverse();
