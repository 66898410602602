import React from "react";
import Map from "./Map";
import Tools from "./Tools";
import styled from "styled-components";
import worldmap from "./world-110m.json";

import Colors from "./Colors";
import Constants from "./Constants";

import Topostore from "./topostore";
import { MapContext, MapContextProvider } from "./MapContext";

const tools = [
  {
    id: "split",
    name: "Split",
    type: "draw"
  },
  {
    id: "merge",
    name: "Merge",
    type: "selection"
  },
  {
    id: "atom",
    name: "Atomize",
    type: "selection"
  },
  {
    id: "quantize",
    name: "Quantize",
    type: "selection"
  },
  {
    id: "delete",
    name: "Delete",
    type: "selection"
  },
  {
    id: "editProperties",
    name: "Edit properties",
    type: "singleSelection"
  }
];

const MainContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

const MapContainer = styled.div`
  position: relative;
  flex: 1;
  background-color: ${Colors.lightgrey};
`;

const ToolsContainer = styled.div`
  position: fixed;
  right: 0;
  height: 100%;
  background-color: ${Colors.white};

  @media screen and (max-width: ${Constants.mediaMaxWidth}) {
    bottom: 0;
    left: 0;
    height: 25%;
  }
`;

const Coordinates = styled.div`
  position: absolute;
  background-color: ${Colors.lightgreyAlpha};
  left: 8px;
  top: 8px;
  padding: 4px;
  min-width: 140px;
`;

const getSplitInstructions = segments => {
  return segments
    .filter(s => s.markers.length > 1)
    .map(({ id, markers }) => ({
      id,
      splitLine: markers.map(({ geoCoordinates }) => geoCoordinates)
    }));
};

class App extends React.Component {
  constructor() {
    super();

    this.onApply = this.onApply.bind(this);
    this.onGeographyClicked = this.onGeographyClicked.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onGeographyDoubleClicked = this.onGeographyDoubleClicked.bind(this);
    this.onToolChanged = this.onToolChanged.bind(this);
    this.clearMarkers = this.clearMarkers.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.clearSelections = this.clearSelections.bind(this);
    this.openTopoFile = this.openTopoFile.bind(this);
    this.readFile = this.readFile.bind(this);
    this.handleNewMap = this.handleNewMap.bind(this);
    this.onMapObjectDelete = this.onMapObjectDelete.bind(this);

    this.mapState = {
      selection: [],
      segments: [{ markers: [] }]
    };

    const mapObjectKey = Object.keys(worldmap.objects)[0];

    this.state = {
      map: worldmap,
      latitude: 0,
      longitude: 0,
      mapObjectKey,
      currentTool: "split",
      currentGeography: null
    };
    this.topostore = new Topostore(map => this.setState(() => ({ map })));

    this.topostore.openMap(this.state.map, mapObjectKey);
  }

  onApply = values => {
    const selection = { ids: Object.keys(this.mapState.selection) };
    switch (this.state.currentTool) {
      case "split":
        this.topostore.split(getSplitInstructions(this.mapState.segments));
        break;
      case "merge":
        this.topostore.merge(selection);
        break;
      case "atom":
        this.topostore.atomize(selection);
        break;
      case "quantize":
        this.topostore.quantize(values);
        break;
      case "delete":
        this.topostore.remove(selection);
        break;
      case "editProperties":
        this.topostore.updateProperties(values);
        break;
      default:
        throw new Error(`Unknown tool ${this.state.currentTool}`);
    }

    this.clearSelections();
  };

  clearSelections = () => {
    this.clearMarkers();
    this.clearSelected();
  };

  clearMarkers = () => {
    for (const segment of this.mapState.segments) {
      for (const { line } of segment.markers) {
        line && line.remove();
      }
    }
    this.mapState.segments = [{ markers: [] }];
  };

  clearSelected = () => {
    for (const { svgItem } of Object.values(this.mapState.selection)) {
      svgItem.attr("fill", "black"); // use svg classes..
    }
    this.mapState.selection = {};
  };

  onGeographyClicked = ({ geography, coordinates, svg }) => {
    switch (tools.filter(t => t.id === this.state.currentTool)[0].type) {
      case "singleSelection":
        if (geography.id in this.mapState.selection) {
          delete this.mapState.selection[geography.id];
          svg.clickedItem.attr("fill", "black"); // use css classes...
          this.setState(() => ({ currentGeography: null }));
        } else {
          for (const [key, { svgItem }] of Object.entries(
            this.mapState.selection
          )) {
            delete this.mapState.selection[key];
            svgItem.attr("fill", "black"); // use css classes...
          }
          this.mapState.selection[geography.id] = { svgItem: svg.clickedItem };
          svg.clickedItem.attr("fill", "grey"); // use css classes...
          this.setState(() => ({ currentGeography: geography }));
        }
        break;
      case "selection":
        if (geography.id in this.mapState.selection) {
          delete this.mapState.selection[geography.id];
          svg.clickedItem.attr("fill", "black"); // use css classes...
        } else {
          this.mapState.selection[geography.id] = { svgItem: svg.clickedItem };
          svg.clickedItem.attr("fill", "grey"); // use css classes...
        }
        break;
      case "draw":
        const currentSegment = this.mapState.segments[
          this.mapState.segments.length - 1
        ];
        if (currentSegment.id && currentSegment.id !== geography.id) return;

        currentSegment.id = geography.id;

        const [x2, y2] = coordinates.xy;

        const lastMarker =
          currentSegment.markers[currentSegment.markers.length - 1];
        let line;
        if (lastMarker) {
          const [x1, y1] = lastMarker.xyCoordinates;
          if (x1 === x2 && y1 === y2) return;

          line = svg.layer
            .append("line")
            .attr("x1", x1)
            .attr("x2", x2)
            .attr("y1", y1)
            .attr("y2", y2)
            .attr("pointer-events", "none")
            .attr("stroke", "hotpink")
            .attr("stroke-width", 2)
            .attr("vector-effect", "non-scaling-stroke");
        }
        currentSegment.markers.push({
          geoCoordinates: coordinates.geo,
          xyCoordinates: coordinates.xy,
          line
        });
        svg.mousePointer.attr("x1", x2);
        svg.mousePointer.attr("y1", y2);
        svg.mousePointer.attr("display", "inline");

        break;
      default:
        return;
    }
  };

  onGeographyDoubleClicked = ({ svg }) => {
    svg.mousePointer.attr("display", "none");
    this.mapState.segments.push({ markers: [] });
  };

  onMouseMove = ({ coordinates, svg }, updateGeoCoordinates) => {
    if (tools.filter(t => t.id === this.state.currentTool)[0].type === "draw") {
      svg.mousePointer
        .attr("x2", coordinates.xy[0])
        .attr("y2", coordinates.xy[1]);
    }
    const [longitude, latitude] = coordinates.geo;
    updateGeoCoordinates({ latitude, longitude });
  };

  onToolChanged = tool => {
    this.clearSelections();
    this.setState(() => ({ currentTool: tool }));
  };

  storeToDisk = map => {
    const json = JSON.stringify(map);
    var a = document.createElement("a");
    a.setAttribute(
      "href",
      "data:text/plain;charset=utf-u," + encodeURIComponent(json)
    );
    a.setAttribute("download", "map.json");
    a.click();
    a.remove();
  };

  openTopoFile = () => {
    const application = this;
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.addEventListener(
      "change",
      e => application.readFile(e.srcElement.files[0], fileSelector),
      false
    );
    fileSelector.click();
  };

  readFile = (file, fileSelector) => {
    const application = this;
    try {
      const reader = new FileReader();
      reader.onload = e => {
        try {
          application.handleNewMap(JSON.parse(e.target.result));
        } catch (e) {
          alert(
            "Unable to load file. Are you sure it is in the topojson format?"
          );
        }
      };
      reader.readAsText(file);
      fileSelector.remove();
    } catch (e) {
      alert(`Unable to load file ${file.name}`);
    }
  };

  onMapObjectDelete(mapObjectKey) {
    this.topostore.deleteMapObject(mapObjectKey);
    const newMapObjectKey = Object.keys(this.state.map.objects).filter(
      x => x !== mapObjectKey
    )[0];
    this.setState(() => ({
      mapObjectKey: newMapObjectKey
    }));
    this.topostore.selectMapObjectKey(newMapObjectKey);
  }

  handleNewMap(file) {
    const mapObjectKey = Object.keys(file.objects)[0];
    this.setState(() => ({ map: file, mapObjectKey }));
    this.topostore.openMap(this.state.map, mapObjectKey);
  }

  render() {
    const toolType = tools.filter(t => t.id === this.state.currentTool)[0].type;

    return (
      <MainContainer>
        <MapContextProvider>
          <MapContext.Consumer>
            {({ geoCoordinates, setGeoCoordinates }) => (
              <MapContainer>
                <Map
                  map={this.state.map}
                  mapObjectKey={this.state.mapObjectKey}
                  onGeographyClicked={this.onGeographyClicked}
                  onGeographyDoubleClicked={this.onGeographyDoubleClicked}
                  onMouseMove={e => this.onMouseMove(e, setGeoCoordinates)}
                  cursor={toolType === "draw" ? "crosshair" : "default"}
                />
                <Coordinates>
                  <div>Latitude: {geoCoordinates.latitude.toFixed(4)}</div>
                  <div>Longitude: {geoCoordinates.longitude.toFixed(4)}</div>
                </Coordinates>
              </MapContainer>
            )}
          </MapContext.Consumer>
        </MapContextProvider>
        <ToolsContainer>
          <Tools
            tools={tools}
            currentTool={this.state.currentTool}
            currentGeography={this.state.currentGeography}
            onToolChanged={this.onToolChanged}
            onApply={this.onApply}
            onUndo={this.topostore.undo}
            onRedo={this.topostore.redo}
            onOpen={this.openTopoFile}
            onStore={() => this.storeToDisk(this.state.map)}
            onMapObjectKeyChanged={mapObjectKey => {
              this.setState(() => ({ mapObjectKey }));
              this.topostore.selectMapObjectKey(mapObjectKey);
            }}
            mapObjectKeys={Object.keys(this.state.map.objects)}
            currentMapObjectKey={this.state.mapObjectKey}
            onMapObjectDelete={this.onMapObjectDelete}
          />
        </ToolsContainer>
      </MainContainer>
    );
  }
}

export default App;
